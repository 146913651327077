<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb
        :breadcrumbs="[
          { label: 'Больничные', routeName: 'SickLeave' },
          { label: 'Создание больничного' },
        ]"
      ></bread-crumb>
      <h4 class="page-title">Создание больничного</h4>
    </div>
    <div class="card">
      <div class="card-body">
        <Form
          :service="service"
          redirectAfterSave="SickLeaveShow"
          :actions="[
            { action: 'save', to: (data) => '/sick-leave/' + data.id },
          ]"
        >
          <FormField
            label="Сотрудник"
            :service="service"
            field="userId"
            :options="userList"
            type="select"
          />
          <FormField
            :service="service"
            field="dtStart"
            label="Начало больничного"
            type="date"
          />
          <FormField
            :service="service"
            field="dtEnd"
            label="Конец больничного"
            type="date"
          />
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import BreadCrumb from "/src/components/ui/BreadCrumb";
import FormField from "@/components/ui/form/FormField.vue";
import SpeedDialBtn from "@/components/ui/SpeedDialBtn.vue";
import SickLeaveService from "../services/SickLeaveService";
import UserService from "@/modules/user/services/UserService";

export default {
  components: {
    BreadCrumb,
    SpeedDialBtn,
    Form,
    FormField,
  },
  data() {
    return {
      service: new SickLeaveService(),
      userService: new UserService(),
      userList: {},
    };
  },
  async created() {
    this.userList = await this.userService.allList();
  },
};
</script>

